$breakpoint-large: 1200px;
$breakpoint-medium: 1024px;
$breakpoint-small: 640px;
$breakpoint-small-landscape: "(orientation: landscape) and (max-width: 860px) and (max-height: 480px)";
$breakpoint-small-portrait: "only screen and (max-width: 805px) and (orientation: portrait)";

$header-z-index: 999;

//All the white colors...
$white1: white;
$white1-with-opacity: rgba($white1, 0.8);
$white1-with-opacity2: rgba($white1, 0.1);
$white1-with-opacity3: rgba($white1, 0.85);
$white2: #f7f7f7;
$white3: #f2f2f2;
$white4: #d1d1d1;
$white5: #EDF2F7;

//All the black colors...
$black1: black;
$black1-with-opacity: rgba($black1, 0.2);
$black1-with-opacity2: rgba($black1, 0.15);

// All the grey colors...
$grey1: #e5e5e5;
$grey1-with-opacity: #e5e5e565;
$grey2: #eeeeee;
$grey3: #b1b1b1;
$grey4: #959595;
$grey5: #cccccc;
$grey5-with-opacity: #cccccc85;
$grey6: #5d5d5d;
$grey7: #767676;
$grey7-with-opacity: #7676767d;
$grey8: #333333;
$grey8-with-opacity: #33333399;
$grey9: #444444;
$grey10: #d9d9d9;

// All the blue colors...
$blue1: #e8f5fc;
$blue2: #e1ebf6;
$blue3: #dbeffa;
$blue4: #b9d7f5;
$blue5: #1467ba29;
$blue6: #CBE1F1;
$blue7: #1467BA;

// All the orange colors...
$orange1: #FFE3D1;
$orange2: #FF6B11;
$orange3: #FFF5EF;

// All the red colors...
$red1: #FFEEEF;
$red2: #E2001A;

$header-height: 54px;



//All the shadow colors...
$shadow1: #0000002b;

$messagebox-timestamp-font-size: 11px;
$messagebox-sender-font-size: 13px;
  
// @deprecated: Component variables
$navBarConfig: (
  background: $white2,
  height: 50px,
  itemColor: $grey4,
);

$cardConfig: (
  borderStyle: 1px solid $grey2,
  itemColor: $grey4,
);

$notificationConfig: (
  disabledColor: $grey7,
);

$popper-border-radius: 4px 4px 4px 4px;
$lightbox-max-width: 100vw;
$lightbox-width: 100%;
$lightbox-height: 100%;
