@use '../../core/theming/variables';
@use '@portal/ui-kit';
@use 'sass:map';

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $background-map: map.get($theme, "background");
  $default-background: map.get($background-map, 'default-background');
  .wen-confirmation-dialog-wrapper {
    background-color: $default-background;
    button.wen-confirmation-dialog-wide-action {
      background-color: transparent;
      border: none;
      border-top: 1px solid variables.$grey3;
      color: variables.$grey3;
      &:not([disabled]) {
        color: ui-kit.get-color-from-palette($primary);
      }
    }
  }
}


