@use 'sass:map';
@use '../../../../../../wen-components/src' as wen-components;

@mixin theme($theme) {
  $background-map: map.get($theme, "background");
  h1.wen-empty-list-title {
    color: wen-components.$var-grey6 !important;
  }
  div.wen-empty-list-description {
    color: wen-components.$var-grey6 !important;
  }
}
