@use '@portal/ui-kit';
@use 'sass:map';

@mixin theme($typography) {
  $font-weight: ui-kit.font-weight($typography, body-1);

  .wen-poll-creation {
    [portal-button]{
      font-weight: $font-weight;
    }
  }
}
