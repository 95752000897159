@use '@portal/ui-kit';
@use 'sass:map';

@mixin theme($theme, $typography) {
  $background-map: map.get($theme, 'background');
  $default-background: map.get($background-map, 'default-background');
  wen-user-chat-list {
    background-color: $default-background;
  }
}
