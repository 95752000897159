@use "sass:map";
@use '../../core/theming/variables';

@mixin add-menu-entry-layout-styles() {

  // "Legacy styling" used border bottom
  // TODO: remove this once all menu entries are using wen-menu-entry-layout
  .wen-menu-entry:not(:last-of-type, .wen-menu-entry-flat) {
    border-bottom: 2px solid variables.$white2;
  }

  .wen-menu-entry-layout-group:has(*) {
    overflow: hidden;
    border-radius: 10px;
    margin-top: 18px;

    // Use margin instead of border with wen-menu-entry-layout
    // TODO: remove border override once all menu entries are using wen-menu-entry-layout
    .wen-menu-entry:not(:last-of-type, .wen-menu-entry-flat) {
      margin-bottom: 2px;
      border-bottom: 0px !important;
    }
  }
}