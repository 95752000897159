@use 'sass:map';
@use '@portal/ui-kit';

@mixin theme($theme, $typography) {
  $primary: map.get($theme, primary);
  $background-map: map.get($theme, 'background');
  $default-background: map.get($background-map, 'default-background');
  $default-foreground: map.get($background-map, 'default-foreground');
  $text-color-dark-grey: map.get($background-map, 'text-color-dark-grey');
  .wen-user-profile-view {
    background-color: $default-foreground;
    .wen-user-view-profile-entry-primary {
      color: ui-kit.get-color-from-palette($primary);
      [menuEntryTitle] {
        color: ui-kit.get-color-from-palette($primary);
      }
    }
    .wen-user-profile-header-container {
      .wen-user-profile-header-username {
        color: $text-color-dark-grey;
      }
    }
    .wen-user-profile-scroller {
      background-color: $default-foreground;
    }
  }
}
