@use "sass:map";

@mixin theme($theme) {
  $background-map: map.get($theme, "background");
  $text-color-dark-grey: map.get($background-map, "text-color-dark-grey");
  .wen-horizontal-card .wen-horizontal-card-info-container {
    h2, h3 {
      color: $text-color-dark-grey;
    }
  }
}
