@use '@portal/ui-kit';
@use 'sass:map';

@mixin theme($theme, $typography) {
  $background-map: map.get($theme, 'background');
  $text-color: map.get($background-map, 'text-color');
  $text-color-dark-grey: map.get($background-map, 'text-color-dark-grey');
  .wen-list-item {
    [wen-list-item-title], .wen-list-item-title {
      color: $text-color-dark-grey;
      @include ui-kit.typography-level($typography, title);
    }
    [wen-list-item-description], .wen-list-item-description {
      color: $text-color;
      @include ui-kit.typography-level($typography, subheading-1);
    }
    [wen-list-item-subtitle], .wen-list-item-subtitle {
      @include ui-kit.typography-level($typography, subheading-2);
    }
  }
}
