@use '@portal/ui-kit';
@use "sass:map";

@mixin theme($theme, $typography) {
  $background-map: map.get($theme, "background");
  $default-background: map.get($background-map, "default-background");
  .wen-onboarding-wrapper {
    background-color: $default-background;
  }
  [portal-headline] strong {
    @include ui-kit.typography-level($typography, 'headline');
  }
}
