@use 'sass:map';

@mixin theme($theme) {
  $background-map: map.get($theme, 'background');
  $default-background: map.get($background-map, 'default-background');
  mat-expansion-panel-header {
    background-color: $default-background;
  }
  .mat-expansion-panel-body {
    background-color: $default-background;
  }
  .mat-expansion-panel-header.mat-expanded:focus, .mat-expansion-panel-header:hover {
    background: $default-background !important;
  }
}
