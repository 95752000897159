@use "@portal/ui-kit";
@use "sass:map";
@use "../../core/theming/variables";
@use "./wen-icon.mixins" as mixins;

@mixin _add-icon-styles($background, $icon-color) {
  background-color: $background;
  color: $icon-color;
}

@mixin theme($theme) {
  $primaryColor: ui-kit.get-color-from-palette(map.get($theme, primary));

  wen-icon {
    &[color="primary"] {
      svg {
        fill: var(--wen-primary-color);
      }
    }
    &.wen-icon-variant-text-subtle {
      @include _add-icon-styles(transparent, variables.$grey8);
    }
    &.wen-icon-variant-filled {
      @include _add-icon-styles($primaryColor, variables.$white1);
    }
    &.wen-icon-variant-secondary-flat {
      @include _add-icon-styles(variables.$grey2, variables.$grey7);
    }
    &.wen-icon-variant-assistive-inlined {
      @include _add-icon-styles(white, variables.$grey7);
    }
    &:not(.wen-icon-colorful) mat-icon.wen-svg-icon {
      path {
        fill: unset;
      }
    }
  }
}
