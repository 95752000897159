@use "sass:map";
@use "../../../../../wen-components/src" as wen-components;

@mixin theme() {

  .wen-form-v2 {
    --wen-form-field-background-color: #{wen-components.$var-grey2};
  }

  $form-field-background-color: wen-components.tokens-get-token(--wen-form-field-background-color, wen-components.$var-white2);

  html body {
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background-color: $form-field-background-color !important;
    }

    .wen-form-field-white .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background-color: wen-components.$var-white1 !important;
    }

    mat-form-field {
      .mat-mdc-text-field-wrapper {
        background-color: wen-components.$var-white2 !important;
      }

      &.wen-form-field-white .mat-mdc-text-field-wrapper {
        background-color: wen-components.$var-white1 !important;
      }
    }
  }
}