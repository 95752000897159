@use '@portal/ui-kit';

@mixin theme($theme, $typography) {
  $font-size: ui-kit.font-size($typography, subheading-1);
  $line-height: ui-kit.line-height($typography, subheading-1);

  wen-embed-notification {
    wen-horizontal-card {
      wen-markdown-text {
        p, strong {
          font-size: $font-size;
          line-height: $line-height;
        }
      }
    }
  }
}