@use 'sass:map';
@use 'sass:color';
@use '@portal/ui-kit';

@mixin theme($theme, $typography) {
  $primary: map.get($theme, primary);
  $background-map: map.get($theme, 'background');
  $background: map.get($background-map, 'search-bar-background-color');

  .wen-search-bar .wen-search-bar-cancel-search {
    color: ui-kit.get-color-from-palette($primary);
  }
  .wen-search-bar-search-field {
    background: $background;
  }
}
