@use 'sass:map';
@use '../../../../../../wen-components/src' as wen-components;

@mixin theme($theme) {
  $background-map: map.get($theme, 'background');
  $secondary-background: map.get($background-map, 'secondary-background');
  $button-background: map.get($background-map, 'default-background');
  wen-channel-featured-view {
    background-color: $secondary-background;
    wen-navigate-to-channel-button {
      background-color: $button-background;
      .wen-navigate-to-channel-button-text-description {
        color: wen-components.$var-grey8 !important;
      }
    }
  }
}
