@mixin add-lazyload-styling() {
  $lazy-load-transition-time: 200ms;
  img {
    &.wen-lazy-load {
      opacity: 0;
      -webkit-filter: blur(5px);
      filter: blur(5px);
      transition: filter $lazy-load-transition-time, -webkit-filter $lazy-load-transition-time;
      &.lazyloading {
        transition: opacity $lazy-load-transition-time;
      }
      &.lazyloaded {
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
      }
    }
  }
}
