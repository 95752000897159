@use "../../../../../wen-components/src" as wen-components;

@mixin add-form-field-styling() {

  // Overwrite the style defined in _form.scss in the portal/ui-kit
  html body .mdc-text-field {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  html body .mat-mdc-form-field-infix {
    min-height: 56px !important;
    padding-bottom: 8px !important;
  }

  html body label[matformfieldfloatinglabel][ng-reflect-floating='false'] {
    top:28px !important;
  }

  html body mat-form-field.mat-form-field-disabled .mat-mdc-text-field-wrapper {
    background-color: wen-components.$var-white2 !important;
  }

  html body mat-form-field.mat-form-field-disabled .mat-mdc-form-field-infix {
    border-bottom: none;
  }

  mat-form-field {

    .mat-mdc-form-field-focus-overlay,
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background-color: transparent;
    }

    label {
      font-weight: 400 !important;
      pointer-events: none !important;
    }
  }
}