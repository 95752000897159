@use 'sass:map';

@mixin theme($theme) {
  $background-map: map.get($theme, 'background');
  $default-foreground: map.get($background-map, 'default-foreground');
  $default-background: map.get($background-map, 'default-background');
  .wen-user-credentials-container {
    height: 100%;
    background-color: $default-foreground;
  }
  .wen-user-credentials-menu-entries, .wen-user-credentials-interact-button, .wen-credential-card-wrapper {
    background-color: $default-background;
  }
}
