@use "sass:map";
@use '@portal/ui-kit';
@use "../../../../../wen-components/src" as wen-components;

@mixin theme($typography) {
  ngx-mat-datepicker-content {
    &.mat-datepicker-content {
      box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
      background-color: wen-components.$var-white1;
      color: wen-components.$var-grey8;
    }

    .mat-calendar-body-label {
      font-size: ui-kit.font-size($typography, button);
      font-weight: ui-kit.font-weight($typography, button);
      color: wen-components.$var-grey4;
    }

    .mat-datepicker-toggle, .mat-calendar-next-button, .mat-calendar-previous-button {
      color: wen-components.$var-grey7;
    }

    .mat-calendar-table-header {
      color: wen-components.$var-grey7;
    }

    .mat-calendar-table-header-divider:after {
      background-color: wen-components.$var-grey1;
    }

    .time-container {
      margin-top: 10px;
    }
    .mat-datepicker-actions {
      padding: 16px 8px;
      justify-content: space-around !important;
    }

    .mat-calendar-body-cell-content {
      border: none;
      color: wen-components.$var-grey8;

      &.mat-calendar-body-selected {
        background-color: var(--wen-primary-color);
        color: wen-components.$var-white1;
      }
    }

    .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
      background-color: var(--wen-primary-color);
      color: wen-components.$var-white1;
    }

    .mat-calendar-body-disabled>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
      color: wen-components.$var-grey5;
    }

    .mat-calendar-body-disabled {
      cursor: default !important;
    }
  }

  .ngx-mat-timepicker {
    .mdc-line-ripple, .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }

    input {
      font-size: ui-kit.font-size($typography, body-1) !important;
    }

    .mat-mdc-form-field-infix {
      padding-top: 4px !important;
    }
  }
}
