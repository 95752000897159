@use "../../../../../wen-components/src" as wen-components;

@mixin add-form-styling() {

  .wen-form,
  .wen-form-v2 {
    --wen-form-field-spacing-size: 1em;
    $form-field-spacing-size: wen-components.tokens-get-token(--wen-form-field-spacing-size);

    width: 100%;
    display: flex;
    overflow: auto;
    flex-direction: column;
    height: 100%;

    .wen-form-group {
      flex: 0 1 auto;
      padding: 1em;

      &.wen-single-group {
        overflow: hidden;

        &:has(.wen-form-field-scroller) {
          padding-right: 0;
        }

        display: flex;
        flex-direction: column;

        .wen-form-group-hint {
          margin-top: 0.5em;
        }
      }

      &:not(:last-child) {
        margin-bottom: 6px;
      }

      mat-form-field {
        &.mat-form-field-invalid {
          .wen-form-field-label {
            color: unset;
          }
        }
      }

      .wen-form-field-scroller {
        overflow-x: hidden;
        overflow-y: auto;
        flex: 0 1 auto;
        padding-right: 0.5em;
      }

      .wen-form-field-wrapper {
        display: flex;
        flex-direction: column;

        &:not(:last-of-type) {
          margin-bottom: $form-field-spacing-size;
        }

        &.wen-image-upload {
          align-items: center;
          padding: 0 24px;
        }

        .wen-form-field-label {
          margin-bottom: 0.5em;
        }

        .wen-form-field {
          width: 100%;
          display: flex;
        }

        .wen-toggle-field {
          justify-content: space-between;

          &:not(:last-of-type) {
            margin-bottom: 0.5em;
          }
        }
      }
    }

    .wen-form-group-hint {
      margin-bottom: 0;
      text-align: center;

      wen-icon {
        display: inline-block;
      }
    }

    .wen-form-actions {
      margin-top: 0.75em;
    }
  }

  .wen-form .custom-form-group {
    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }
}