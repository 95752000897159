@use "sass:map";
@use "@portal/ui-kit";
@use "./variables";
@use "../../../projects/wen-widget/src/index" as wen-widget;
@use "../../../projects/wen-components/src/index" as wen-components;

$primary-map: map.merge(
  $map1: ui-kit.$portal-red,
  $map2: (
    700: var(--wen-primary-color),
  ),
);

$secondary-map: ui-kit.$portal-dark-blue;

$primary: ui-kit.define-palette($primary-map);
$secondary: ui-kit.define-palette($secondary-map);

/* Font config */
$typography: ui-kit.define-typography-config(
  /* Font style */ $font-family: #{$font},
  /* Display 1 */ $display-1: ui-kit.define-typography-level(24px, 21px, 600),
  /* Display 1 */ $display-2: ui-kit.define-typography-level(24px, 21px, 400),
  /* H1 */ $headline: ui-kit.define-typography-level(20px, 21px, 600),
  /* H2 */ $title: ui-kit.define-typography-level(16px, 21px, 600),
  /* H3 */ $subheading-1: ui-kit.define-typography-level(13px, 17px, 400),
  /* H4 */ $subheading-2: ui-kit.define-typography-level(11px, 15px, 600),
  /* Body */ $body-1: ui-kit.define-typography-level(16px, 24px, 400),
  /* Subtitle */ $body-2: ui-kit.define-typography-level(16px, 21px, 400),
  /* Buttons */ $button: ui-kit.define-typography-level(18px, 24px, 600),
  /* Caption*/ $caption: ui-kit.define-typography-level(11px, 15px, 400)
);
@include ui-kit.core($typography, $typography);

$wen-light-theme: wen-widget.define-light-theme($primary, $secondary);
@include ui-kit.all-component-themes($wen-light-theme);
@include wen-widget.all-themes($wen-light-theme, $typography);
@include wen-components.all-themes($wen-light-theme, $typography);

body,
html {
  position: fixed;
  overflow: hidden;
}
