@use 'sass:map';

@mixin theme($theme) {
  $background-map: map.get($theme, "background");
  $border-color: map.get($background-map, 'border-color');
  $default-background: map.get($background-map, 'header-background-color');
  .wen-mobile-header {
    background-color: $default-background;
    border-bottom: 1px solid $border-color;
  }
}
