@use "sass:map";

@mixin theme($theme) {
  $background-map: map.get($theme, "background");
  $default-background: map.get($background-map, "default-background");
  $default-foreground: map.get($background-map, "default-foreground");
  $text-color: map.get($background-map, "text-color-black");
  .wen-channel-detail {
    background-color: $default-foreground;
    .wen-detail-header-cover-wrapper {
      background-color: $default-background;
    }
    .wen-channel-detail-content-group:not(.wen-channel-detail-submenus) {
      background-color: $default-background;
    }
    .wen-channel-detail-content-group .wen-channel-detail-info-text {
      color: $text-color;
    }
    .wen-detail-header-subheader {
      background-color: $default-background;
    }
  }
}
