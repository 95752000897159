@use '../../../../../wen-components/src' as wen-components;

@mixin add-crop-dialog-styling() {
  .wen-image-crop-dialog-panel {
    /* stylelint-disable-next-line custom-property-pattern */
    --cropper-overlay-color: wen-components.$var-white2;
    max-height: 640px !important;
    min-height: 400px !important;
    height: auto !important;
    mat-dialog-container {
      height: auto !important;
      padding: 0;
      border-radius: 0;
    }
    @media (orientation: portrait) and (max-width: 640px),
      (orientation: landscape) and (max-width: 1023px) {
      min-height: 100vh !important;
      min-width: 100vw !important;
    }
  }
}
