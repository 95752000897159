@use '@portal/ui-kit';
@use '../../core/theming/variables';
@use '../../core/theming/mixins';

@mixin theme($theme, $typography) {
  wen-section {
    .wen-section-header {
      [section-header-title] {
        @include ui-kit.typography-level($typography, title);
        @include mixins.line-clamp(2);
        color: variables.$grey8;
      }
      [section-header-action] {
        @include ui-kit.typography-level($typography, body-2);
        color: variables.$grey6;
        white-space: nowrap;
      }
    }
  }
}
