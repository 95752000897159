@use "sass:map";
@use "../../../core/theming/variables";
@use "@portal/ui-kit";

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $primary-color: ui-kit.get-color-from-palette($primary);
  mat-checkbox.borderless-check {
    .mdc-checkbox__ripple {
      display: none;
    }
    .mdc-checkbox__background {
      border-color: transparent !important;
    }
    &.mat-mdc-checkbox-checked {
      .mdc-checkbox__background {
        svg {
          transform: scale(1.5);
          path {
            stroke: $primary-color !important;
          }
        }
      }
    }
  }
}
