@use 'sass:map';
@use '@portal/ui-kit';
@use '../../core/theming/variables';

@mixin theme($theme, $typography) {
  $primary: map.get($theme, primary);
  $background-map: map.get($theme, "background");
  $default-foreground: map.get($background-map, "default-foreground");

  .wen-scroll-to-button-notification-count {
    background: ui-kit.get-color-from-palette($primary);
  }
  .wen-scroll-to-button button {
    background-color: $default-foreground;
    wen-icon {
      color: variables.$grey7;
    }
  }
}
