@use 'sass:map';

@mixin theme($theme) {
  $background-map: map.get($theme, "background");
  $separator-background: map.get($background-map, 'separator-background');
  .wen-separator-background {
    .wen-separator-arrow {
      border-right-color: $separator-background !important;
    }
    .wen-separator-text {
      color: white;
      background-color: $separator-background;
    }
    hr.wen-separator-line {
      border-bottom-color: $separator-background !important;
    }
    .wen-separator-date {
      background: $separator-background;
    }
  }
}
