@use "@portal/ui-kit";
@use 'sass:map';

@mixin theme($theme, $typography) {
  $background-map: map.get($theme, "background");
  $default-background: map.get($background-map, "default-background");
  $text-color-dark-grey: map.get($background-map, 'text-color-dark-grey');
  .wen-detail-header {
    background-color: $default-background;
    [wen-detail-header-title] {
      color: $text-color-dark-grey;
      @include ui-kit.typography-level($typography, title);
    }
    [wen-detail-header-description] {
      @include ui-kit.typography-level($typography, body-2);
    }
    .wen-detail-header-subheader {
      background-color: $default-background;
    }
    img.wen-detail-header-square-image-content {
      background-color: $default-background;
    }
  }
}
