.cdk-overlay-container .action-sheet-dialog {
  max-width: 100vw !important;
  width: 100vw !important;
  margin: 0px !important;
  .mat-mdc-dialog-container {
    border-radius: 12px !important;
    margin: 0px !important;
    padding: 10px !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }
}

.mat-mdc-dialog-container .mdc-dialog__content {
  // TODO: investigate if it is needed in next angular version
  letter-spacing: inherit !important;
}
