@use 'sass:map';
@use './nav-bar-item/nav-bar-item.theme';

@mixin theme($theme, $typography, $config) {

  $background-map: map.get($theme, 'background');
  $default-foreground: map.get($background-map, 'default-foreground');
  $height: map.get($config, 'height');

  .wen-nav-bar {
    .wen-nav-bar-content {
      background: $default-foreground;
      height: $height;
    }
    @include nav-bar-item.theme($theme, $typography, $config);
  }
}
