@use 'sass:map';

@mixin theme($theme) {
  $background-map: map.get($theme, 'background');
  $default-foreground: map.get($background-map, 'default-foreground');
  $secondary-background: map.get($background-map, 'secondary-background');

  .wen-channel-scheduled-messages-content {
    background-color: $secondary-background;
  }
  .wen-channel-schedules-edit-wrapper {
    background-color: $default-foreground;
  }
}