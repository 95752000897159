@use '@portal/ui-kit';
@use 'sass:map';


@mixin theme($theme, $typography) {
  $background-map: map.get($theme, 'background');
  $text-color: map.get($background-map, 'text-color');
  $text-color-disabled: map.get($background-map, 'separator-background');

  
  wen-chat-detail-user-list {
    .wen-user-role {
      color: $text-color;
      @include ui-kit.typography-level($typography, subheading-1);
    }

    .disabled {
      color: $text-color-disabled;
    }

    .wen-chat-detail-add-member-counter {
      &:not(.disabled) {
        color: $text-color;
      }
    }
  }  
}
