@use 'sass:map';
@use '@portal/ui-kit';

@mixin theme($theme, $typography) {
  $background-map: map.get($theme, 'background');
  $default-background: map.get($background-map, 'default-background');
  $text-color-dark-grey: map.get($background-map, 'text-color-dark-grey');
  .wen-home-container {
    background-color: $default-background;
    .wen-home-featured wen-message-preview-card {
      background-color: $default-background;
    }
    .wen-home-text {
      @include ui-kit.typography-level($typography, title);
      color: $text-color-dark-grey;
    }
  }
}
