@use "sass:map";
@use "../../../../../wen-components/src" as wen-components;

@mixin theme($theme) {
  $background-map: map.get($theme, "background");
  $default-background: map.get($background-map, "default-background");
  $default-foreground: map.get($background-map, "default-foreground");

  .wen-form-v2 {
    --wen-form-background-color: #{wen-components.$var-white2};
    --wen-form-group-background-color: #{wen-components.$var-white2};
    --wen-form-group-border-color: #{wen-components.$var-white2};
  }

  $form-background-color: wen-components.tokens-get-token(--wen-form-background-color, $default-foreground);
  $form-group-background-color: wen-components.tokens-get-token(--wen-form-group-background-color, $default-background);
  $form-group-border-color: wen-components.tokens-get-token(--wen-form-group-border-color, wen-components.$var-white4);

  .wen-form, .wen-form-v2 {
    background-color: $form-background-color;

    .wen-form-group,
    .wen-form-actions {
      &:not(:first-of-type) {
        border-top: 1px solid $form-group-border-color;
      }

      border-bottom: 1px solid $form-group-border-color;
      background-color: $form-group-background-color;

      .wen-form-field-label {
        color: wen-components.$var-grey7;
      }

      .wen-form-field-wrapper {
        background-color: $form-group-background-color;
      }
    }

    .wen-form-group-hint {
      color: wen-components.$var-grey7;
    }
  }
}