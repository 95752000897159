@use 'sass:map';
@use '@portal/ui-kit';
@use './mixins';
@use './variables';

// A place to override portal default styles which doesn't fit the WEN theme
@mixin add-portal-overrides($theme) {
  $primary: map.get($theme, primary);

  html body [portal-title] {
    margin-bottom: 6px;
  }

  html body * {
    font-weight: unset;
    font-style: unset;

    em {
      font-style: italic;
    }

    strong {
      font-weight: bold;
    }
  }

  button[portal-button] {
    filter: none !important;
    outline: none;
  }

  portal-button[inlined]:hover, portal-button[inlined]:focus,
  [portal-button][inlined]:hover, [portal-button][inlined]:focus {
    background: transparent !important;
    color: ui-kit.get-color-from-palette($primary);
    @include mixins.wen-interactive();
  }

  portal-image img {
    padding: 0 !important;
  }

  button {
    @include mixins.wen-interactive();
  }

  button[portal-button][inlined][disabled] {
    color: variables.$grey4;
  }


  *[tabindex] {
    outline: none;
  }

  * {
    -webkit-tap-highlight-color: transparent;
    user-select: none;
  }

  input, textarea {
    user-select: auto !important;
  }

  b {
    font: inherit !important;
    font-weight: 600 !important;
  }

  a {
    @include mixins.wen-link;
  }

  portal-progress-bar {
    position: relative;
    mat-progress-bar {
      position: absolute !important;
      top: 0 !important;
      width: 100% !important;
    }
  }

  .wen-highlighted {
    color: variables.$grey4;
  }
}

@mixin add-headings() {
  html body h1 {
    margin-top: 0;
    margin-bottom: 8px;
  }

  html body h2 {
    margin-top: 0;
    margin-bottom: 6px;
  }

  html body h3 {
    margin-top: 0;
    margin-bottom: 6px;
  }

  html body h4 {
    margin-top: 0;
    margin-bottom: 6px;
  }
}

@mixin add-mat-overrides($theme) {
  $primary: map.get($theme, primary);

  mat-checkbox {
    .mdc-checkbox__background {
      border-color: variables.$grey4 !important;
    }
    &.mat-mdc-checkbox-checked {
      .mdc-checkbox__background {
        border-color: ui-kit.get-color-from-palette($primary) !important;
      }
    }
  }
  mat-form-field {
    &.wen-hide-underline .mdc-line-ripple {
      display: none;
    }

    .mdc-text-field--filled .mdc-floating-label--float-above {
      transform: translateY(-96%) scale(0.75);
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) {
      .mdc-floating-label {
        color: rgba(0, 0, 0, 0.6);
      }

      .mdc-line-ripple:before {
        border-bottom-color: rgba(0, 0, 0, 0.42);
      }

      .mdc-line-ripple::after {
        border-bottom-color: rgba(0, 0, 0, 0.42);
      }
    }
  }
  ////////////////////////////////////////////////////////////////////////////
  // Angular 15 theming workaround                                          //
  ////////////////////////////////////////////////////////////////////////////

  /* stylelint-disable custom-property-pattern */
  .mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary {
    --mat-mdc-fab-color: white !important;
  }
  .mat-mdc-slide-toggle.mat-primary {
    --mdc-switch-selected-focus-state-layer-color: var(--wen-primary-color);
    --mdc-switch-selected-handle-color: var(--wen-primary-color);
    --mdc-switch-selected-hover-state-layer-color: var(--wen-primary-color);
    --mdc-switch-selected-pressed-state-layer-color: var(--wen-primary-color);
    --mdc-switch-selected-focus-handle-color: var(--wen-primary-color);
    --mdc-switch-selected-hover-handle-color: var(--wen-primary-color);
    --mdc-switch-selected-pressed-handle-color: var(--wen-primary-color);
    --mdc-switch-selected-focus-track-color: var(--wen-primary-color);
    --mdc-switch-selected-hover-track-color: var(--wen-primary-color);
    --mdc-switch-selected-pressed-track-color: var(--wen-primary-color);
    --mdc-switch-selected-track-color: var(--wen-primary-color);
    .mdc-switch__track {
      opacity: 0.5;
    }
    .mdc-switch__ripple {
      display: none;
    }
    /* stylelint-enable custom-property-pattern */

    ////////////////////////////////////////////////////////////////////////////
  }
}

@mixin add-extra-typography($typography) {
  $font-family: map.get($typography, font-family);
  html body [wen-header-big] {
    @include ui-kit.font-shorthand(24px, 600, 32px, $font-family);
    margin-bottom: 16px;
  }
  html body {
    [wen-header-huge], [wen-header-huge] > strong {
      @include ui-kit.font-shorthand(32px, 600, 38px, $font-family);
      margin: 0 0 8px 0;
    }
  }
}

@mixin add-wen-overlay-styles($theme) {
  $background-map: map.get($theme, 'background');
  $popper-backdrop: map.get($background-map, 'popper-backdrop');
  .wen-filter-dialog, .wen-selector-dialog {
    .mat-mdc-dialog-container {
      height: auto !important;
      border-radius: 12px !important;
      margin: 0px !important;
      padding: 10px !important;
      box-shadow: none !important;
      background-color: transparent !important;
    }
  }

  .wen-lightbox-dialog {
    .mat-mdc-dialog-container {
      /* stylelint-disable */
      --mdc-dialog-container-color: transparent;
      --mdc-dialog-container-shape: 0;
      /* stylelint-enable */
    }
  }

  .pswp {
    /* stylelint-disable */
    --pswp-root-z-index: 0;
    /* stylelint-enable */
    img {
      cursor: auto;

      &.pswp__img--placeholder {
        z-index: 1;
      }
    }
    .pswp_error_img {
      width: 100%;
      height: 100%;
    }
    .pswp__video-container {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .wen-registration-dialog {
    .mat-mdc-dialog-container {
      padding: 0;
      border-radius: 0;
    }
  }

  .cdk-overlay-container .wen-confirmation-dialog {
    max-width: 600px !important;
    margin: 0px !important;
    .mat-mdc-dialog-container {
      border-radius: 12px !important;
      margin: 0px !important;
      padding: 10px !important;
      box-shadow: none !important;
      background-color: transparent !important;
    }
  }

  .wen-message-box-popper {
    box-shadow: 0 2px 6px variables.$grey8-with-opacity !important;
  }

  .popper-backdrop {
    background-color: $popper-backdrop;
  }

  .cdk-overlay-pane {
    z-index: 1001;
  }

  .cdk-overlay-dark-backdrop {
    background-color: $popper-backdrop;
  }

  .cdk-overlay-container {
    position: absolute;
    z-index: auto;

    .wen-lightbox-dialog {
      max-width: variables.$lightbox-max-width !important;
      width: variables.$lightbox-width;
      height: variables.$lightbox-height;
      mat-dialog-container {
        padding: 0;
        background: transparent;
      }
    }
  }

  .cdk-global-overlay-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
  }
  .wen-context-menu-panel {
    max-height: 80%;
    width: 50%;
  }
  @include mixins.only-mobile-style() {
    .wen-context-menu-panel {
      width: 100%;
    }
  }
}

@mixin add-wen-snackbar-styles($theme) {
  $background-map: map.get($theme, 'background');
  $default-background: map.get($background-map, 'default-background');
  .mat-mdc-snack-bar-container {
    margin: 12px;
    background: $default-background;
     /* stylelint-disable-next-line custom-property-pattern */
    --mdc-snackbar-container-color: $default-background;
    padding: 0 !important;
    &.wen-snackbar-notification {
      .mat-mdc-snack-bar-label{
        padding-left: 0;
      }
      .mdc-snackbar__surface {
        border-radius: 12px;
      }
      border-radius: 12px;
      margin: 24px;
      color: variables.$grey8;
       /* stylelint-disable-next-line custom-property-pattern */
      --mdc-snackbar-supporting-text-weight: 600;
       /* stylelint-disable-next-line custom-property-pattern */
      --mdc-snackbar-supporting-text-color: variables.$grey8;
    }
  }
}

@mixin add-wen-color-helpers() {
  [wen-primary-color] {
    color: var(--wen-primary-color);
  }
  [wen-primary-background-color] {
    background-color: var(--wen-primary-color);
  }
}

@mixin add-wen-action-menu-styles($theme) {
  $background-map: map.get($theme, 'background');
  $background-color: map.get($background-map, 'default-background');
  $text-color: map.get($background-map, 'text-color-dark-grey');
  $icon-color: variables.$grey7;
  .wen-action-menu-content {
    display: block;
    min-width: 150px;
    background-color: $background-color;
    border-radius: variables.$popper-border-radius;
    button {
      color: $text-color;
      width: 100%;
      border: none;
      background-color: $background-color;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-end;
      white-space: nowrap;
      padding: 8px !important;

      &[portal-title] {
        margin-bottom: 0;
      }

      wen-icon {
        color: $icon-color;
        margin-right: 12px;
      }
    }
  }
}

@mixin add-cdk-virtual-scroll-viewport-override() {
  cdk-virtual-scroll-viewport {
    height: 100%;
    .cdk-virtual-scroll-content-wrapper {
      max-width: 100%;
    }
  }
}

@mixin add-time-picker-mobile-view-override() {
  @include mixins.only-mobile-style() {
    ngx-mat-datetime-content {
      margin: -24px;
    }
  }
}

@mixin add-wen-markdown-text-override($typography) {
  wen-markdown-text {
    table {
      th {
        font-weight: bold;
        border: 1px solid variables.$grey5;
        border-bottom: 2px solid variables.$black1;
      }

      td {
        border: 1px solid variables.$grey5;
      }
    }

    blockquote {
      border-left: 1px solid variables.$grey8;
    }

    span {
      h1 {
        @include ui-kit.typography-level($typography, 'display-1');
        line-height: normal;
      }

      h2 {
        @include ui-kit.typography-level($typography, 'headline');
        line-height: normal;
      }

      h3 {
        @include ui-kit.typography-level($typography, 'title');
      }

      h4 {
        font-size: 13px;
        @include ui-kit.typography-level($typography, 'subheading-1');
      }

      h5 {
        @include ui-kit.typography-level($typography, 'subheading-2');
      }

      h6 {
        @include ui-kit.typography-level($typography, 'subheading-2');
      }
    }
  }
}

@mixin add-wen-christmas-overlay() {
  .wen-christmas-overlay {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
}

@mixin add-wen-fab-button() {
  .mat-mdc-fab, .mat-mdc-mini-fab {
    box-shadow: none !important;
  }
}

@mixin add-wen-slide-toggle() {
  .mdc-switch__icons {
    display: none;
  }
}
