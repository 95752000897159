@use 'sass:map';

@mixin theme($theme) {
  $background-map: map.get($theme, "background");
  $default-foreground: map.get($background-map, 'default-foreground');
  $default-background: map.get($background-map, 'default-background');
  .wen-event-detail-layout {
    background-color: $default-foreground;
  }
  .wen-box {
    background-color: $default-background;
    .expandable-container-wrapper {
      background-color: $default-background;
    }
  }
}
