@use 'sass:map';

@mixin theme($theme) {
  $background-map: map.get($theme, "background");
  $default-background: map.get($background-map, 'default-background');
  .wen-app-detail-content-group {
    background-color: $default-background;
  }
}

