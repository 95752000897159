@use '@portal/ui-kit';
@use '../../../core/theming/variables';
@use '../../../core/theming/mixins';

@mixin theme($theme, $typography) {
  wen-card {
    [card-title] {
      @include mixins.line-clamp(2);
      @include ui-kit.typography-level($typography, title);
      color: variables.$grey8;
    }

    [card-description] {
      @include mixins.line-clamp(1);
      @include ui-kit.typography-level($typography, subheading-1);
      color: variables.$grey4;
    }
  }
}
