@use 'sass:map';

@mixin theme($theme) {
  $background-map: map.get($theme, "background");
  $default-background: map.get($background-map, 'default-background');
  $text-color: map.get($background-map, 'text-color');
  $fade: map.get($background-map, 'fade');
  .fade {
    background: $fade;
  }
  .button {
    color: $text-color !important;
    background-color: $default-background !important;
  }
}
