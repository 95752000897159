@use 'sass:map';
@use '@portal/ui-kit';

@mixin color-content($color) {
  mat-icon, .wen-nav-bar-item-label {
    color: $color; // Color for font icons
    svg { // Color for custom svg icons
      fill: $color !important;
    }
  }
}

@mixin theme($theme, $typography, $config) {

  $primary: map.get($theme, primary);
  $defaultItemColor: map.get($config, 'itemColor');

  .wen-nav-bar-item {
    .wen-nav-bar-item-link {
      &:not(.wen-nav-bar-item-link-active) {
        @include color-content($defaultItemColor);
      }
      &.wen-nav-bar-item-link-active {
        @include color-content(ui-kit.get-color-from-palette($primary));
      }
    }
    .wen-nav-bar-item-label {
      @include ui-kit.typography-level($typography, caption);
    }
  }
}
