@use "sass:map";
@use "../../../../../../../../wen-components/src" as wen-components;

@mixin theme($theme) {
  $background-map: map.get($theme, "background");
  $default-foreground: map.get($background-map, "default-foreground");
  $default-background: map.get($background-map, "default-background");
  wen-email-settings {
    background-color: $default-foreground;
    .wen-profile-email-setting-page-entry-groups {
      .wen-profile-email-setting-page-entry-group {
        background-color: $default-foreground;
        .wen-interact-button-menu-style {
          background-color: $default-background;
          span {
            color: wen-components.$var-grey8;
          }
        }
        .wen-profile-email-setting-page-email-info {
          background-color: $default-background;
        }
      }
    }
  }
}
