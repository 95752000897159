@use '@portal/ui-kit';
@use 'sass:map';

@mixin theme($theme, $typography) {
  $background-map: map.get($theme, 'background');
  $text-color: map.get($background-map, 'text-color');
  
  .wen-channel-list-empty-list-title {
    @include ui-kit.typography-level($typography, title);
  }
  .wen-channel-list-empty-list-text {
    @include ui-kit.typography-level($typography, body-2);
  }
  .wen-channel-list-item-description {
    color: $text-color;
    @include ui-kit.typography-level($typography, subheading-1);
  }
}
