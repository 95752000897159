@use 'sass:map';
@use '@portal/ui-kit';

@mixin theme($theme, $typography, $notificationConfig) {
  $primary: map.get($theme, primary);
  $disabledColor: map.get($notificationConfig, 'disabledColor');

  .wen-notification-count {
    &:not(.wen-notification-count-disabled) .wen-notification-count-content {
      background: ui-kit.get-color-from-palette($primary);
    }
    &.wen-notification-count-disabled .wen-notification-count-content {
      background: $disabledColor;
    }
  }
}
