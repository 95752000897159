@use 'sass:map';

@mixin theme($theme) {
  $background-map: map.get($theme, "background");
  $default-background: map.get($background-map, 'default-background');
  .wen-profile-information-content-group {
    background-color: $default-background;
    .expandable-container-wrapper {
      background-color: $default-background;
    }
  }
}
