@use '@portal/ui-kit';

@mixin theme($typography) {
  wen-create-welcome-markdown {
    wen-markdown-text {
      span h4 {
        @include ui-kit.typography-level($typography, title);
      }

      p {
        @include ui-kit.typography-level($typography, body-2);
      }
    }
  }
}
