@use '@portal/ui-kit';
@use 'sass:map';

@mixin theme($theme, $typography) {
  $background-map: map.get($theme, 'background');
  $text-color: map.get($background-map, 'text-color');

  wen-channel-subscriber-list {
    .wen-user-role {
      display: block;
      color: $text-color;
      @include ui-kit.typography-level($typography, subheading-1);
    }
  }
}
