@use "@portal/ui-kit";
@use "sass:map";
@use "../../core/theming/variables";

@mixin _add-location-preview-styles($background, $color, $dismissColor, $borderRadius, $iconSpacing) {
  background-color: $background;
  color: $color;
  border-radius: $borderRadius;

  .wen-location-preview-icon,
  .wen-location-preview-text {
    @content;
  }

  .wen-location-preview-icon {
    margin-right: $iconSpacing;
  }

  .wen-location-preview-dismiss {
    color: $dismissColor;
  }
}

@mixin theme($theme, $typography) {
  $primaryColor: ui-kit.get-color-from-palette(map.get($theme, primary));

  wen-location-preview {
    &.wen-location-preview-variant-normal {
      @include _add-location-preview-styles(variables.$grey2, variables.$grey8, $primaryColor, 20px, 12px) {
        @include ui-kit.typography-level($typography, "body-2");
      }
    }

    &.wen-location-preview-variant-small {
      @include _add-location-preview-styles(variables.$white2, variables.$grey9, $primaryColor, 4px, 8px) {
        @include ui-kit.typography-level($typography, "subheading-1");
        font-weight: 600;
      }
    }
  }
}
