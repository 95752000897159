@use 'sass:map';

@mixin theme($theme) {
  $background-map: map.get($theme, 'background');
  $default-background: map.get($background-map, 'header-background-color');
  $header-text-color: map.get($background-map, 'header-text-color');
  wen-app-header:not(.wen-app-secondary-header) {
    .wen-app-header-wrapper {
      background-color: $default-background;
      .wen-app-header-title-text {
        color: $header-text-color;
      }
    }
  }
}