@use './variables';

@mixin only-mobile-style() {
  @media (max-width: variables.$breakpoint-medium) {
      @content;
  }
}

@mixin with-animation-motion() {
  @media (prefers-reduced-motion: no-preference) {
      @content;
  }
}

@mixin line-clamp($lines) {
  @if $lines == 1 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  } @else {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
  }
}

@mixin desktop-header-size($static: false) {
  @if $static {
    height: 100px;
  }
  max-height: 100px;
}

@mixin desktop-header-styles() {
  background: variables.$white2;
}

@mixin desktop-header-border() {
  border-bottom: 1px solid variables.$white4;
  box-sizing: border-box;
}

@mixin wen-interactive() {
  cursor: pointer !important;
}

@mixin wen-link() {
  color: #1467ba;
  text-decoration: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: revert;
  }
}

@mixin wen-wrap-text-preserve-whitespace() {
  @include wen-wrap-text(pre-wrap);
}

@mixin wen-wrap-text-preserve-linebreakes-only() {
  @include wen-wrap-text(pre-line);
}

@mixin wen-wrap-text-collapse-whitespace() {
  @include wen-wrap-text(normal);
}

@mixin wen-wrap-text($white-space-value) {
  white-space: $white-space-value;
  word-break: break-word;
}

@mixin wen-mat-form-field-customization() {
  ::ng-deep mat-form-field {
    @content;
  }
}
