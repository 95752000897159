@use 'sass:map';
@use '@portal/ui-kit';

@mixin theme($theme) {
  
  mat-checkbox, .mdc-checkbox {

    &.cursor-initial {
      .mdc-checkbox {
        cursor: initial;
      }
    }

    .mdc-checkbox__ripple {
      display: none;
    }
  }

  mat-checkbox:not(.borderless-check) {

    &.mat-mdc-checkbox-checked {
      .mdc-checkbox__background {

        svg {
          transform: scale(0.8);
        }
      }
    }
  }
}
