@use "@portal/ui-kit";
@use "sass:map";
@use "../../../core/theming/variables";

@mixin _add-action-styles($background, $text-color, $icon-color, $disabled-background, $disabled-text-color, $border-color) {
  background-color: $background;

  @if ($border-color) {
    border: 1px solid $border-color;
  }

  wen-icon {
    color: var(--wen-action-icon-color, $icon-color);
  }

  .wen-action-text-content {
    color: var(--wen-action-text-color, $text-color);
  }

  &.wen-action-disabled {
    background-color: $disabled-background;
    wen-icon {
      color: var(--wen-disabled-action-text-color, $disabled-text-color);
    }

    .wen-action-text-content {
      color: var(--wen-disabled-action-text-color, $disabled-text-color);
    }
  }
}

@mixin theme($theme) {
  $primaryColor: ui-kit.get-color-from-palette(map.get($theme, primary));

// ($background, $text-color, $icon-color, $disabled-background, $disabled-text-color, $border-color)

  wen-action {
    &.wen-action-variant-filled {
      @include _add-action-styles($primaryColor, variables.$white1, variables.$white1, variables.$grey5, variables.$white1, null);
    }

    &.wen-action-variant-outlined {
      @include _add-action-styles(transparent, $primaryColor, $primaryColor, transparent, variables.$grey5, $primaryColor);
    }

    &.wen-action-variant-text {
      @include _add-action-styles(transparent, $primaryColor, $primaryColor, transparent, variables.$grey7, null);
    }

    &.wen-action-variant-text-subtle {
      @include _add-action-styles(transparent, variables.$grey8, variables.$grey8, transparent, variables.$grey5, null);
    }

    &.wen-action-variant-text-emphasis {
      @include _add-action-styles(transparent, variables.$white1, variables.$white1, transparent, variables.$grey7, null);
    }
    &.wen-action-variant-emphasis-subtle {
      @include _add-action-styles(variables.$white1, variables.$grey8, variables.$grey8, variables.$white1, variables.$grey5, null);
    }
  }
}
