@use 'sass:map';

@mixin theme($theme) {
  $background-map: map.get($theme, "background");
  $border-color: map.get($background-map, 'border-color');
  .wen-mobile-primary-outlet {
    background-color: #f7f7f7;
  }
  .wen-mobile-navigation-bar {
    border-top: 1px solid $border-color;
  }
}
